@if (assignmentsStoreService.historyLoading()) {
  <ion-progress-bar type="indeterminate"></ion-progress-bar>
}
@if (assignmentsStoreService.history(); as datasource) {
  <table [dataSource]="datasource" [trackBy]="trackBy" class="ion-color-primary" mat-table>
    <ng-container matColumnDef="icon">
      <th *matHeaderCellDef mat-header-cell></th>
      <td *matCellDef="let element; dataSource: datasource" mat-cell @tableCell>
        <fa-icon class="ion-color-secondary" [icon]="faArrowFromLeft" [fixedWidth]="true"></fa-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="initiatedAt">
      <th *matHeaderCellDef mat-header-cell>Datum</th>
      <td @tableCell *matCellDef="let element; dataSource: datasource" mat-cell>
        {{ element.initiatedAt | date: 'MMM d, y' }}
        <span>{{ element.initiatedAt | date: 'HH:mm' }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th *matHeaderCellDef mat-header-cell></th>
      <td @tableCell *matCellDef="let element; dataSource: datasource" mat-cell>
        <app-transition-log-record [logRecord]="element"></app-transition-log-record>
      </td>
    </ng-container>

    <ng-container matColumnDef="initiatedBy">
      <th *matHeaderCellDef mat-header-cell>Gebruiker</th>
      <td @tableCell *matCellDef="let element; dataSource: datasource" mat-cell>
        @if (element.initiatedBy) {
          {{ element.initiatedBy.fullName }}
        }
      </td>
    </ng-container>

    @let displayColumns = ['icon', 'initiatedAt', 'description', 'initiatedBy'];
    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
  </table>
}
