<ion-textarea
  [formControl]="descriptionControl()"
  color="tertiary"
  mode="md"
  fill="solid"
  data-test="descriptionController"
  autoGrow="true"
  rows="7"
  placeholder="Voeg hier een opmerking toe aan deze opdracht"
>
</ion-textarea>
@if (activeAssignmentStoreService.savingDescription()) {
  <ion-spinner></ion-spinner>
}
