<div class="status">
  @if (this.assignmentHasSlot().purpose === AssignmentHasSlotPurposeEnum.ABSENCE) {
    <fa-icon [icon]="faVirus"> </fa-icon>
  }
</div>

<div class="date">
  {{ this.assignmentHasSlot().slot.timePeriod.start | date: 'dd-MM-yyyy' }}
</div>

<div class="time">
  {{ this.assignmentHasSlot().slot.timePeriod | ttDate: { format: 'HH:mm', divider: ' - ' } }}
</div>

<ng-container [formGroup]="form">
  @let breakTime = form.get('breakTime');
  @let isRequiredError = !!(breakTime && breakTime.hasError('required'));
  @let breakIsLongerThenWorkingTimesError =
    !!(breakTime && breakTime.hasError('breakIsLongerThenWorkingTimes'));
  <tt-number-input
    class="break"
    formControlName="breakTime"
    data-test="has-slot-break-input"
    type="text"
    aria-label="pauze"
    fill="outline"
    [stepInterval]="5"
    [placeholder]="0"
    [errorText]="
      isRequiredError
        ? 'Voer een geldige waarde in.'
        : breakIsLongerThenWorkingTimesError
          ? 'De pauze is langer dan de lengte van de shift.'
          : ''
    "
  ></tt-number-input>

  <div class="time-input">
    @if (!this.assignmentHasSlot().slot.isCombined) {
      <ng-container formGroupName="actualTimePeriod">
        <tt-time-control
          formControlName="start"
          ariaLabel="start time"
          [fill]="'outline'"
          data-test="has-slot-actualTime-start"
        ></tt-time-control>
        -
        <tt-time-control
          formControlName="end"
          ariaLabel="end time"
          [fill]="'outline'"
          data-test="has-slot-actualTime-end"
        ></tt-time-control>
        <ion-button
          size="small"
          [matTooltip]="'Werktijden goedkeuren'"
          fill="clear"
          [disabled]="savingActualWorkingTimes() || form.invalid"
          (click)="confirmTime()"
        >
          @if (savingActualWorkingTimes() | async) {
            <ion-spinner slot="icon-only"></ion-spinner>
          } @else {
            <ion-icon name="checkmark-outline" slot="icon-only"></ion-icon>
          }
        </ion-button>
        @if (form.get('actualTimePeriod'); as actualTime) {
          <div>
            @if (
              !actualTime.disabled && actualTime.dirty && actualTime.hasError('tsRangeNotInRange')
            ) {
              Je kan niet beginnen met een shift, nadat je gestopt bent.
            }
            @if (actualTime.get('start'); as startTime) {
              @if (
                !startTime.disabled &&
                startTime.dirty &&
                (startTime.hasError('pattern') || startTime.hasError('required'))
              ) {
                Geef een geldige starttijd op.
              }
            }
            @if (actualTime.get('end'); as endTime) {
              @if (
                !endTime.disabled &&
                endTime.dirty &&
                (endTime.hasError('pattern') || endTime.hasError('required'))
              ) {
                Geef een geldige eindtijd op.
              }
            }
          </div>
        }
      </ng-container>
    }
  </div>
</ng-container>

<div class="role">
  {{ targetRole()?.name }}
</div>

<ion-toggle
  class="saldo"
  data-test="has-slot-saldo-input"
  color="secondary"
  aria-label="heeft saldo"
  (ionChange)="changeIsBillable()"
  [disabled]="
    (assignmentsStoreService.savingIsBillable$ | async) === true ||
    activeAssignmentsPermissionsStoreService.canChangeIsBillable() ||
    this.assignmentHasSlot().purpose !== AssignmentHasSlotPurposeEnum.SCHEDULING
  "
  [checked]="this.assignmentHasSlot().slot.lesson.isBillable"
>
</ion-toggle>
<ion-button
  class="actions"
  [id]="'popover-button-' + (this.assignmentHasSlot().slot | jsonLdSelect)"
  fill="clear"
  size="small"
  color="medium"
>
  <ion-icon icon="ellipsis-vertical-outline"></ion-icon>
</ion-button>
<ion-popover
  [trigger]="'popover-button-' + (this.assignmentHasSlot().slot | jsonLdSelect)"
  [showBackdrop]="false"
  [dismissOnSelect]="true"
>
  <ng-template>
    <ion-content>
      <ion-list>
        @if (activeAssignmentStoreService.assignment()?.state === AssignmentStateEnum.UNASSIGNED) {
          <ion-item
            [matTooltip]="
              canRemoveSlot()
                ? 'Verwijder shift uit deze opdracht.'
                : 'Deze shift kan niet worden verwijderd uit deze opdracht.'
            "
            (click)="removeSlot()"
            [disabled]="!canRemoveSlot()"
            [button]="true"
            [detail]="false"
            lines="none"
          >
            <fa-icon [icon]="faTrashCan" slot="start"></fa-icon>
            Shift ontkoppelen
          </ion-item>
        }
        @if (this.assignmentHasSlot().purpose === AssignmentHasSlotPurposeEnum.SCHEDULING) {
          <ion-item
            (click)="unassignSlot()"
            [disabled]="
              this.assignmentHasSlot().slot.displayAsCombined &&
              !this.assignmentHasSlot().slot.isCombined
            "
            [button]="true"
            [detail]="false"
            lines="none"
          >
            <fa-icon [icon]="faArrowRightFromBracket" slot="start"></fa-icon>
            Uitroosteren
          </ion-item>
        }
        @if (this.assignmentHasSlot().purpose === AssignmentHasSlotPurposeEnum.ABSENCE) {
          <ion-item
            [disabled]="
              this.assignmentHasSlot().slot.displayAsCombined &&
              !this.assignmentHasSlot().slot.isCombined
            "
            (click)="markAsPresent()"
            [button]="true"
            [detail]="false"
            lines="none"
          >
            <fa-icon [icon]="faVirus" slot="start"></fa-icon>
            Ziekmelden ongedaan maken
          </ion-item>
        }
      </ion-list>
    </ion-content>
  </ng-template>
</ion-popover>
