import { Component, inject } from '@angular/core';
import { IonSkeletonText } from '@ionic/angular/standalone';
import { AssignmentsPayoutStoreService } from '@scheduler-frontend/data-access-assignment';

@Component({
  selector: 'app-payout-information',
  templateUrl: './payout-information.component.html',
  styleUrls: ['./payout-information.component.scss'],
  standalone: true,
  imports: [IonSkeletonText],
})
export class PayoutInformationComponent {
  protected readonly assignmentsPayoutStoreService = inject(AssignmentsPayoutStoreService);
}
