import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, input } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowRightFromBracket, faVirus } from '@fortawesome/pro-duotone-svg-icons';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonPopover,
  IonSpinner,
  IonToggle,
  ModalController,
} from '@ionic/angular/standalone';
import {
  AssignmentAssignmentHasSlotContract,
  AssignmentHasSlotPurposeEnum,
  AssignmentStateEnum,
  getRealTimePeriod,
} from '@scheduler-frontend/assignment-contracts';
import {
  ActiveAssignmentsPermissionsStoreService,
  ActiveAssignmentStoreService,
  AssignmentsStoreService,
} from '@scheduler-frontend/data-access-assignment';
import { RolesStoreService } from '@scheduler-frontend/data-access-roles';
import { TtSimpleModalComponent } from '@techniek-team/components/modal';
import { TtNumberInputControlComponent } from '@techniek-team/components/number-input';
import { TtTimeInputControlComponent } from '@techniek-team/components/time-input';
import { jsonLdSelectId, JsonLdSelectIdPipe } from '@techniek-team/tt-ngrx';
import { TtDateRangePipe } from '@techniek-team/tt-range';
import { differenceInMinutes, formatISO, isAfter } from 'date-fns';
import { addIcons } from 'ionicons';
import { checkmarkOutline, ellipsisVerticalOutline } from 'ionicons/icons';
import { map } from 'rxjs/operators';

export type AssignmentHasSlotRowFormValue = FormGroup<AssignmentHasSlotRowForm>['value'];

interface AssignmentHasSlotRowForm {
  breakTime: FormControl<number>;

  actualTimePeriod: FormGroup<{
    start: FormControl<Date>;
    end: FormControl<Date>;
  }>;
}

@Component({
  selector: 'app-assignment-has-slot-item',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatTableModule,
    MatTooltipModule,
    ReactiveFormsModule,
    TtNumberInputControlComponent,
    TtTimeInputControlComponent,
    IonButton,
    IonIcon,
    IonSpinner,
    IonToggle,
    IonPopover,
    IonContent,
    IonList,
    IonItem,
    TtDateRangePipe,
    JsonLdSelectIdPipe,
  ],
  templateUrl: './assignment-has-slot-item.component.html',
  styleUrls: ['./assignment-has-slot-item.component.scss'],
})
export class AssignmentHasSlotItemComponent {
  protected readonly rolesStoreService = inject(RolesStoreService);

  protected readonly activeAssignmentsPermissionsStoreService = inject(
    ActiveAssignmentsPermissionsStoreService,
  );

  private readonly modalController = inject(ModalController);

  protected readonly assignmentsStoreService = inject(AssignmentsStoreService);

  protected readonly activeAssignmentStoreService = inject(ActiveAssignmentStoreService);

  protected readonly faTrashCan = faTrashCan;

  protected readonly faArrowRightFromBracket = faArrowRightFromBracket;

  protected readonly faVirus = faVirus;

  protected readonly AssignmentHasSlotPurposeEnum = AssignmentHasSlotPurposeEnum;

  protected readonly AssignmentStateEnum = AssignmentStateEnum;

  public assignmentHasSlot = input.required<AssignmentAssignmentHasSlotContract>();

  public targetRole = computed(() => {
    return this.rolesStoreService.roleEntities()[
      jsonLdSelectId(this.assignmentHasSlot().slot.role)
    ];
  });

  protected readonly form = new FormGroup({
    breakTime: new FormControl<number>(
      { value: 0, disabled: true },
      {
        nonNullable: true,
        validators: [Validators.required, Validators.min(0), this.validateBreakTime],
      },
    ),
    actualTimePeriod: new FormGroup(
      {
        start: new FormControl<Date>(new Date(), {
          nonNullable: true,
          validators: [Validators.required],
        }),
        end: new FormControl<Date>(new Date(), {
          nonNullable: true,
          validators: [Validators.required],
        }),
      },
      { validators: this.timeDateRangeValidator },
    ),
  });

  public setForm = effect(() => {
    const hasSlot = this.assignmentHasSlot();
    this.form.reset(
      {
        breakTime: hasSlot.breakTime,
        actualTimePeriod: {
          start: new Date(getRealTimePeriod(hasSlot).start),
          end: new Date(getRealTimePeriod(hasSlot).end),
        },
      },
      { emitEvent: false },
    );
    if (!this.targetRole()?.allowBreakTime) {
      this.form.controls['breakTime'].disable();
    }
  });

  protected canRemoveSlot = toSignal(
    toObservable(this.assignmentHasSlot).pipe(
      map((hasSlot) =>
        this.activeAssignmentsPermissionsStoreService.canRemoveSlotFromAssignment$(hasSlot),
      ),
    ),
  );

  protected savingActualWorkingTimes = toSignal(
    toObservable(this.assignmentHasSlot).pipe(
      map((hasSlot) =>
        this.assignmentsStoreService.savingActualWorkingTimes(jsonLdSelectId(hasSlot)),
      ),
    ),
  );

  protected changeIsBillable(): void {
    const lesson = this.assignmentHasSlot().slot.lesson;
    this.activeAssignmentStoreService.setIsBillable(
      jsonLdSelectId(this.assignmentHasSlot()),
      !lesson.isBillable,
    );
  }

  protected unassignSlot(): void {
    this.assignmentsStoreService.unassignSlotFromActiveAssigment(this.assignmentHasSlot());
  }

  protected async removeSlot(): Promise<void> {
    const confirmModal: HTMLIonModalElement = await this.modalController.create({
      component: TtSimpleModalComponent,
      componentProps: {
        title: 'Shift openstellen',
        message: 'Deze shift zal worden ontkoppeld van deze opdracht.',
      },
    });
    await confirmModal.present();
    const { role } = await confirmModal.onWillDismiss();

    if (role === 'confirm') {
      this.assignmentsStoreService.removeSlotFormAssignment(
        jsonLdSelectId(this.assignmentHasSlot().slot),
      );
    }
  }

  protected markAsPresent(): void {
    this.assignmentsStoreService.markAsPresent(jsonLdSelectId(this.assignmentHasSlot()));
  }

  protected confirmTime(): void {
    if (this.form.invalid || this.form.disabled) {
      return;
    }

    const formValue = this.form.getRawValue();

    this.activeAssignmentStoreService.updateActualWorkingTimes(
      jsonLdSelectId(this.assignmentHasSlot()),
      {
        start: formatISO(formValue.actualTimePeriod.start),
        end: formatISO(formValue.actualTimePeriod.end),
        inclusiveStart: true,
        inclusiveEnd: false,
      },
      this.targetRole()?.allowBreakTime ? +formValue.breakTime : undefined,
    );
  }

  /**
   * Form validator which checks if the time is correct.
   */
  private timeDateRangeValidator(group: AbstractControl): ValidationErrors | null {
    if (!group || !(group instanceof FormGroup)) {
      return null;
    }

    const value: NonNullable<AssignmentHasSlotRowFormValue['actualTimePeriod']> =
      group.getRawValue();
    if (!value.start || !value.end) {
      return { tsRangeTimeNotSet: { value: group } };
    }
    return isAfter(group.value.end, group.value.start)
      ? null
      : { tsRangeNotInRange: { value: group } };
  }

  /**
   * Make sure the length of the break is not longer than the length of the shift
   */
  private validateBreakTime(control: AbstractControl): ValidationErrors | null {
    if (!control || !(control instanceof FormControl) || !control.parent) {
      return null;
    }
    const shiftLength: number = differenceInMinutes(
      control.parent.value?.actualTimePeriod?.end,
      control.parent.value?.actualTimePeriod?.start,
    );
    const breakLength: number = +control.getRawValue();

    if (shiftLength > breakLength) {
      return null;
    }

    return { breakIsLongerThenWorkingTimes: { value: breakLength } };
  }

  constructor() {
    addIcons({
      checkmarkOutline: checkmarkOutline,
      ellipsisVerticalOutline: ellipsisVerticalOutline,
    });
  }
}
