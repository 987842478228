<tt-modal (cancel)="close()">
  <tt-modal-title slot="start" color="tertiary">
    @if (activeAssignmentStoreService.assignment()?.businessService; as businessService) {
      {{
        businessServicesStoreService.businessServiceEntities()[businessService | jsonLdSelect]?.name
      }}
    } @else {
      <ion-skeleton-text animated="true" style="width: 60%"></ion-skeleton-text>
    }
  </tt-modal-title>

  <ion-list slot="start">
    <ion-item
      [button]="true"
      lines="none"
      [class.selected]="currentSegment === AssignmentModalSegments.OVERVIEW"
      (click)="segmentChanged(AssignmentModalSegments.OVERVIEW)"
    >
      <fa-icon slot="start" [icon]="faCheckToSlot"></fa-icon>
      <ion-label>Algemeen</ion-label>
    </ion-item>
    <ion-item
      [button]="true"
      lines="none"
      [class.selected]="currentSegment === AssignmentModalSegments.PAYOUT"
      (click)="segmentChanged(AssignmentModalSegments.PAYOUT)"
    >
      <fa-icon slot="start" [icon]="faMoneyCheckDollar"></fa-icon>
      <ion-label>Uitbetaling</ion-label>
    </ion-item>
    <ion-item
      [button]="true"
      lines="none"
      [class.selected]="currentSegment === AssignmentModalSegments.HISTORY"
      (click)="segmentChanged(AssignmentModalSegments.HISTORY)"
    >
      <fa-icon slot="start" [icon]="faCabinetFiling"></fa-icon>
      <ion-label>Historie</ion-label>
    </ion-item>
  </ion-list>

  <app-edit-status slot="start"></app-edit-status>
  <app-edit-assignment-description slot="start"></app-edit-assignment-description>

  @switch (currentSegment) {
    @default {
      <tt-modal-title>Algemeen</tt-modal-title>
      <app-assignment-general></app-assignment-general>
    }
    @case (AssignmentModalSegments.PAYOUT) {
      <tt-modal-title>Uitbetaling</tt-modal-title>
      <app-payout-details></app-payout-details>
    }
    @case (AssignmentModalSegments.HISTORY) {
      <tt-modal-title>Historie</tt-modal-title>
      <app-assignment-history></app-assignment-history>
    }
  }

  <ion-button (click)="close()" slot="action" color="lyceo-green" fill="clear">Sluiten </ion-button>
</tt-modal>
