@if (assignmentsPayoutStoreService.activeAssignmentCompensation()) {
  <app-payout-information></app-payout-information>
}
<app-edit-payout></app-edit-payout>

@if (
  assignmentsPayoutStoreService.savingAssignmentCompensations() ||
  assignmentsStoreService.loadingAssignmentCompensations()
) {
  <ion-progress-bar type="indeterminate"></ion-progress-bar>
}

@if (assignmentsPayoutStoreService.activeAssignmentCompensation(); as compensation) {
  @let dataSource = compensation.assignmentCompensationLines;
  <div class="compensations">
    <table
      mat-table
      class="ion-color-primary"
      [dataSource]="compensation.assignmentCompensationLines"
      [trackBy]="assignmentCompensationLinesTrackBy"
    >
      <ng-container matColumnDef="articleCode">
        <th mat-header-cell *matHeaderCellDef>Artikelcode</th>
        <td mat-cell *matCellDef="let element; dataSource: dataSource">
          <span>{{ element.isExtra ? 'Extra: ' : '' }}</span>
          {{ ArticleCodeEnumDisplayValues[element.articleCode] }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Omschrijving</th>
        <td mat-cell *matCellDef="let element; dataSource: dataSource">
          {{ element.description }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef class="fit-content">Datum</th>
        <td mat-cell class="fit-content" *matCellDef="let element; dataSource: dataSource">
          {{ element.date | date: 'dd-MM-yyyy' }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="quantity">
        <th mat-header-cell class="fit-content" *matHeaderCellDef>Aantal</th>
        <td mat-cell class="fit-content" *matCellDef="let element; dataSource: dataSource">
          {{ element.quantity | number: '1.0-2' }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Bedrag</th>
        <td mat-cell *matCellDef="let element; dataSource: dataSource">
          {{ element.amount | currency: 'EUR' : 'symbol' : '1.2-4' }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef>Subtotaal</th>
        <td mat-cell *matCellDef="let element; dataSource: dataSource">
          {{ element.subtotal | currency: 'EUR' : 'symbol' : '1.2-4' }}
        </td>
        <td mat-footer-cell *matFooterCellDef>
          {{ compensation.total | currency: 'EUR' : 'symbol' : '1.2-4' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element; dataSource: dataSource">
          @if (element.isExtra || element.articleCode === PayoutArticleCodeEnum.SLOT_PREMIUM) {
            @let id = element['@id'] | jsonLdSelect;
            <ion-button
              [disabled]="
                !activeAssignmentsPermissionsStoreService.canDeleteAssignmentCompensationLine()
              "
              (click)="deleteLine(id)"
            >
              @if (!assignmentsPayoutStoreService.savingCompensationLine()) {
                <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
              } @else {
                <ion-spinner></ion-spinner>
              }
            </ion-button>
          }
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      @let displayColums =
        ['articleCode', 'description', 'date', 'quantity', 'amount', 'total', 'actions'];
      <tr mat-header-row *matHeaderRowDef="displayColums"></tr>
      <tr
        mat-row
        *matRowDef="let row; let i = index; columns: displayColums"
        [class.extra]="row.isExtra"
      ></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="displayColums"
        [class.ion-hide]="!assignmentsPayoutStoreService.activeAssignmentCompensation()"
      ></tr>
    </table>

    @if (activeAssignmentStoreService.assignment()?.restrictAssignmentCompensationAccess) {
      <div class="access-denied">
        <h2>
          Toegang geweigerd
          <fa-icon [icon]="faEyeSlash"> visibility_off </fa-icon>
        </h2>
        <p>
          De uitbetalingsdetails van deze opdracht mogen niet door jou worden ingezien.<br />
          Deze opdracht heeft namelijk een product waarbij deze restrictie is ingesteld.
        </p>
      </div>
    }

    @if (activeAssignmentStoreService.assignment(); as assignment) {
      <ion-button
        (click)="addLine()"
        [disabled]="
          assignmentsPayoutStoreService.activeCompensationAllowedArticleCodes().length === 0 ||
          !activeAssignmentsPermissionsStoreService.canCreateAssignmentCompensationLine()
        "
        fill="clear"
        class="ion-float-right"
        size="small"
      >
        Toevoegen
      </ion-button>
    }
  </div>
}

@if (assignmentsStoreService.activeAssignmentCompensationNotFound()) {
  <div class="compensations" style="width: 80%">
    <p>
      De vergoeding voor deze opdracht is nog niet bekend.<br />
      Het is nog niet duidelijk of deze begeleider wel of geen vergoeding ontvangt voor deze
      opdracht,<br />
      en zoja wat de vergoeding dan moet zijn.
    </p>
    @if (activeAssignmentStoreService.assignment(); as assignment) {
      @let role =
        rolesStoreService.roleEntities()[assignment.assignmentHasSlots[0].slot.role | jsonLdSelect];
      @let businessService =
        businessServicesStoreService.businessServiceEntities()[
          role?.businessService | jsonLdSelect
        ];
      <p>
        Neem contact op met
        <a target="_blank" href="https://helpdesk.mijnskoleo.nl/">applicatiebeheer</a>
        en informeer of in Perza vergoedingsregels zijn aangemaakt <br />
        voor {{ assignment.candidate?.fullName }} voor de rol {{ role?.toString() }} in dienst
        {{ businessService?.name }}.
      </p>
    }
  </div>
}
