<ion-list class="candidate">
  <ion-item lines="none">
    <ion-label data-test="assignment-general-fullName">
      @if (activeAssignmentStoreService.assignedCandidate(); as candidate) {
        {{ candidate?.fullName }}
      } @else {
        <ion-skeleton-text [animated]="true"></ion-skeleton-text>
      }
    </ion-label>
  </ion-item>

  <ion-item lines="none">
    <ion-label data-test="assignment-general-phoneNumber">
      @if (activeAssignmentStoreService.assignedCandidate(); as candidate) {
        {{ candidate?.phoneNumber }}
      } @else {
        <ion-skeleton-text [animated]="true" style="width: 80px"></ion-skeleton-text>
      }
    </ion-label>
  </ion-item>

  <ion-item lines="none">
    <ion-label data-test="assignment-general-emailAddress">
      @if (activeAssignmentStoreService.assignedCandidate(); as candidate) {
        {{ candidate?.emailAddress }}
      } @else {
        <ion-skeleton-text [animated]="true"></ion-skeleton-text>
      }
    </ion-label>
  </ion-item>

  @if (activeAssignmentStoreService.assignmentLocation(); as location) {
    <ion-item lines="none">
      <ion-label>
        <strong>Locatie</strong>
        <p>{{ location.name }}</p>
      </ion-label>
    </ion-item>
  } @else {
    <ion-item lines="none">
      <ion-label>
        <ion-skeleton-text [animated]="true"></ion-skeleton-text>
      </ion-label>
    </ion-item>
  }
</ion-list>

@if (activeAssignmentsPermissionsStoreService.canReadSelfAssignable()) {
  @if (activeAssignmentStoreService.assignment(); as assignment) {
    <app-self-assign-form data-test="assignment-general-self-assign-form"></app-self-assign-form>
    <app-urgent-form></app-urgent-form>
  }
}

<app-assignment-has-slot-table></app-assignment-has-slot-table>
