@if (logRecord().previousState; as previousState) {
  De opdracht is veranderd van status&nbsp;
  <span class="ion-color-{{ previousState }}">
    {{ AssignmentStateDisplayValues[previousState] }}
    <fa-icon [fixedWidth]="true" [icon]="faExclamationCircle"></fa-icon>
  </span>
  &nbsp;naar de status&nbsp;
  <span class="ion-color-{{ logRecord().enteredState }}">
    {{ AssignmentStateDisplayValues[logRecord().enteredState] }}
    <fa-icon [fixedWidth]="true" [icon]="faExclamationCircle"></fa-icon> </span
  >.
} @else {
  De opdracht is aangemaakt met de status status&nbsp;
  <span class="ion-color-{{ logRecord().enteredState }}">
    {{ AssignmentStateDisplayValues[logRecord().enteredState] }}
    <fa-icon [fixedWidth]="true" [icon]="faExclamationCircle"></fa-icon> </span
  >.
}
