import { CommonModule } from '@angular/common';
import { Component, effect, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  SelectCustomEvent,
} from '@ionic/angular/standalone';
import {
  AssignmentStateDisplayValues,
  AssignmentStateEnum,
} from '@scheduler-frontend/assignment-contracts';
import {
  ActiveAssignmentStoreService,
  TransitionEnum,
  TransitionToMessage,
} from '@scheduler-frontend/data-access-assignment';
import { CapitalizePipe } from '@techniek-team/common';

@Component({
  selector: 'app-edit-status',
  standalone: true,
  imports: [
    CommonModule,
    CapitalizePipe,
    ReactiveFormsModule,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
  ],
  templateUrl: './edit-status.component.html',
  styleUrls: ['./edit-status.component.scss'],
})
export class EditStatusComponent {
  protected readonly TransitionToMessage = TransitionToMessage;

  protected readonly AssignmentStateDisplayValues = AssignmentStateDisplayValues;

  protected readonly AssignmentStateEnum = AssignmentStateEnum;

  protected readonly activeAssignmentStoreService = inject(ActiveAssignmentStoreService);

  protected readonly assignmentStatusControl = new FormControl<TransitionEnum | null>({
    value: null,
    disabled: true,
  });

  private setAssignmentStatusControlState = effect(() => {
    const transitions = this.activeAssignmentStoreService.activeAssignmentTransitions();
    if (transitions.length === 0) {
      this.assignmentStatusControl.disable({ emitEvent: false });
      return;
    }
    this.assignmentStatusControl.enable({ emitEvent: false });
  });

  private setAssignmentStatusControlValue = effect(() => {
    const assignment = this.activeAssignmentStoreService.assignment();
    if (!assignment) {
      return;
    }
    this.assignmentStatusControl.patchValue(assignment.state as unknown as TransitionEnum, {
      emitEvent: false,
    });
  });

  protected transition(ev: Event) {
    const event = ev as SelectCustomEvent<TransitionEnum>;
    this.activeAssignmentStoreService.applyTransition(event.detail.value);
  }
}
