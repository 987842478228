import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { IonItem, IonLabel, IonList, IonSkeletonText } from '@ionic/angular/standalone';
import {
  ActiveAssignmentsPermissionsStoreService,
  ActiveAssignmentStoreService,
} from '@scheduler-frontend/data-access-assignment';
import { AssignmentHasSlotTableComponent } from './assignment-has-slot-table/assignment-has-slot-table.component';
import { SelfAssignFormComponent } from './self-assign-form/self-assign-form.component';
import { UrgentFormComponent } from './urgent-form/urgent-form.component';

@Component({
  selector: 'app-assignment-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    UrgentFormComponent,
    SelfAssignFormComponent,
    AssignmentHasSlotTableComponent,
    IonList,
    IonItem,
    IonLabel,
    IonSkeletonText,
  ],
})
export class GeneralComponent {
  protected readonly activeAssignmentStoreService = inject(ActiveAssignmentStoreService);

  protected readonly activeAssignmentsPermissionsStoreService = inject(
    ActiveAssignmentsPermissionsStoreService,
  );
}
