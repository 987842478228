import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { IonSpinner, IonTextarea } from '@ionic/angular/standalone';
import { AssignmentStateEnum, isState } from '@scheduler-frontend/assignment-contracts';
import { ActiveAssignmentStoreService } from '@scheduler-frontend/data-access-assignment';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'app-edit-assignment-description',
  templateUrl: './edit-assignment-description.component.html',
  styleUrls: ['./edit-assignment-description.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, IonTextarea, IonSpinner],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditAssignmentDescriptionComponent {
  protected readonly activeAssignmentStoreService = inject(ActiveAssignmentStoreService);

  protected readonly descriptionControl = computed(() => {
    const assignment = this.activeAssignmentStoreService.assignment();
    const form = new FormControl<string | null>(assignment?.description ?? null);

    if (assignment && isState(assignment.state, AssignmentStateEnum.APPROVED)) {
      form.disable({ emitEvent: false });
    }
    return form;
  });

  private saveOnDescriptionChangeSubscription = this.descriptionControl()
    .valueChanges.pipe(
      distinctUntilChanged(),
      takeUntilDestroyed(),
      filter(() => this.descriptionControl().valid),
      debounceTime(1000),
    )
    .subscribe((description) => {
      this.activeAssignmentStoreService.setActiveAssignmentDescription(description);
    });
}
