<div class="payment-checkbox">
  <ion-toggle
    (ionChange)="updateAssignmentPayout('mustPerformCompensation', $event)"
    [checked]="assignmentsPayoutStoreService.activeAssignmentMustPerformCompensation()"
    labelPlacement="start"
    color="secondary"
  >
    Uitbetalen
  </ion-toggle>

  <ion-toggle
    labelPlacement="start"
    color="secondary"
    (ionChange)="updateAssignmentPayout('automaticTravelCompensation', $event)"
    [checked]="assignmentsPayoutStoreService.activeAssignmentAutomaticTravelCompensation()"
    [disabled]="!assignmentsPayoutStoreService.activeAssignmentMustPerformCompensation()"
  >
    Automatische reiskostenvergoeding
  </ion-toggle>
</div>
