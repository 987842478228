<ion-list>
  <ion-list-header data-test="urgent-form-header">
    Urgentie
    @if (activeAssignmentStoreService.savingIsUrgent()) {
      <ion-spinner data-test="urgent-form-spinner"></ion-spinner>
    }
  </ion-list-header>
  <ion-item lines="none">
    <ion-toggle
      data-test="urgent-form-toggle"
      labelPlacement="end"
      (ionChange)="editIsUrgent()"
      color="secondary"
      [formControl]="urgentForm()"
    >
      Spoed
    </ion-toggle>
  </ion-item>
</ion-list>
