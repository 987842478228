<h4>Algemene informatie</h4>

<div>
  <div class="label">Project ID</div>
  <div data-test="payout-information-projectCode">
    @if (assignmentsPayoutStoreService.activeAssignmentCompensation(); as compensation) {
      {{ compensation.projectCode }}
    } @else {
      <ion-skeleton-text animated="true" style="width: 80px"></ion-skeleton-text>
    }
  </div>
</div>
<div>
  <div class="label">Divisie</div>
  <div data-test="payout-information-division">
    @if (assignmentsPayoutStoreService.activeAssignmentCompensation(); as compensation) {
      {{ compensation.division }}
    } @else {
      <ion-skeleton-text animated="true" style="width: 40px"></ion-skeleton-text>
    }
  </div>
</div>
