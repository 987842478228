import { ChangeDetectionStrategy, Component, effect, inject, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCabinetFiling, faMoneyCheckDollar } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckToSlot } from '@fortawesome/pro-regular-svg-icons';
import {
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonSkeletonText,
  ModalController,
} from '@ionic/angular/standalone';
import {
  AssignmentStateDisplayValues,
  AssignmentStateEnum,
} from '@scheduler-frontend/assignment-contracts';
import { ActiveAssignmentStoreService } from '@scheduler-frontend/data-access-assignment';
import { BusinessServicesStoreService } from '@scheduler-frontend/data-access-business-services';
import {
  TtModalComponent,
  TtModalTitleComponent,
  TtSimpleModalComponent,
} from '@techniek-team/components/modal';
import { JsonLdSelectIdPipe } from '@techniek-team/tt-ngrx';
import { format } from 'date-fns';
import { EditAssignmentDescriptionComponent } from './edit-description/edit-assignment-description.component';
import { EditStatusComponent } from './edit-status/edit-status.component';
import { GeneralComponent } from './general/general.component';
import { HistoryComponent } from './history/history.component';
import { PayoutComponent } from './payout/payout.component';

export enum AssignmentModalSegments {
  OVERVIEW = 'OVERVIEW',
  PAYOUT = 'PAYOUT',
  HISTORY = 'HISTORY',
}

@Component({
  selector: 'app-edit-assignment-modal',
  templateUrl: './assignment-modal.component.html',
  styleUrls: ['./assignment-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TtModalComponent,
    FontAwesomeModule,
    ReactiveFormsModule,
    EditAssignmentDescriptionComponent,
    GeneralComponent,
    PayoutComponent,
    HistoryComponent,
    EditStatusComponent,
    IonSkeletonText,
    IonList,
    IonItem,
    IonLabel,
    IonButton,
    TtModalTitleComponent,
    JsonLdSelectIdPipe,
  ],
})
export class AssignmentModalComponent {
  private readonly modalController = inject(ModalController);

  @Input()
  public set segment(segment: AssignmentModalSegments) {
    if (!segment) {
      return;
    }

    this.currentSegment = segment;
  }

  protected readonly activeAssignmentStoreService = inject(ActiveAssignmentStoreService);

  protected readonly businessServicesStoreService = inject(BusinessServicesStoreService);

  protected readonly faCheckToSlot = faCheckToSlot;

  protected readonly faMoneyCheckDollar = faMoneyCheckDollar;

  protected readonly faCabinetFiling = faCabinetFiling;

  protected readonly AssignmentModalSegments: typeof AssignmentModalSegments =
    AssignmentModalSegments;

  protected readonly AssignmentStateDisplayValues: typeof AssignmentStateDisplayValues =
    AssignmentStateDisplayValues;

  protected readonly AssignmentStateEnum: typeof AssignmentStateEnum = AssignmentStateEnum;

  protected currentSegment: string = AssignmentModalSegments.OVERVIEW;

  public segmentChanged(tab: AssignmentModalSegments): void {
    this.segment = tab;
  }

  public close(): Promise<boolean> {
    this.activeAssignmentStoreService.clearActiveAssignment();
    return this.modalController.dismiss(false, 'close');
  }

  private checkCancelledWarning = effect(() => {
    const assignment = this.activeAssignmentStoreService.assignment();
    if (
      assignment &&
      assignment.state === AssignmentStateEnum.CANCELLED &&
      assignment.assignmentHasSlots.length
    ) {
      const date: string = format(
        assignment.assignmentHasSlots[0].actualTimePeriod.start,
        'dd-MM-yyyy',
      );
      this.modalController
        .create({
          component: TtSimpleModalComponent,
          cssClass: ['stack-modal'],
          componentProps: {
            title: 'Let op',
            message: `Deze opdracht bevat een geannuleerde shift die niet uitgeroosterd kan worden.
            Probeer ${assignment.candidate?.fullName} nog in te plannen op een andere shift op ${date}!`,
            buttons: [
              {
                text: 'OK',
              },
            ],
          },
        })
        .then((confirmModal) => confirmModal.present());
    }
  });
}
