import { transition, trigger, useAnimation } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  MatCell,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
} from '@angular/material/table';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faArrowFromLeft } from '@fortawesome/pro-regular-svg-icons';
import { IonProgressBar } from '@ionic/angular/standalone';
import {
  noTableDataEnterAnimation,
  noTableDataLeaveAnimation,
  tableEnterAnimation,
  tableLeaveAnimation,
  tableLoadingEnterAnimation,
  tableLoadingLeaveAnimation,
} from '@scheduler-frontend/common/animation';
import { ActiveAssignmentStoreService } from '@scheduler-frontend/data-access-assignment';
import { LogRecordContract } from '@scheduler-frontend/log-contracts';
import { TypeSafeMatCellDef } from '@scheduler-frontend/mat-cell-def';
import { TransitionLogRecordComponent } from './transittion-log-record/transition-log-record.component';

@Component({
  selector: 'app-assignment-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TransitionLogRecordComponent,
    DatePipe,
    IonProgressBar,
    MatTable,
    MatHeaderCellDef,
    TypeSafeMatCellDef,
    MatCell,
    MatHeaderCell,
    FaIconComponent,
    MatColumnDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRowDef,
    MatRow,
  ],
  animations: [
    trigger('tableCell', [
      transition(':enter', useAnimation(tableEnterAnimation)),
      transition(':leave', useAnimation(tableLeaveAnimation)),
    ]),
    trigger('tableLoading', [
      transition(':enter', useAnimation(tableLoadingEnterAnimation)),
      transition(':leave', useAnimation(tableLoadingLeaveAnimation)),
    ]),
    trigger('noTableData', [
      transition(':enter', useAnimation(noTableDataEnterAnimation)),
      transition(':leave', useAnimation(noTableDataLeaveAnimation)),
    ]),
  ],
})
export class HistoryComponent {
  protected readonly assignmentsStoreService = inject(ActiveAssignmentStoreService);

  protected trackBy(_index: number, item: LogRecordContract): string {
    return item['@id'] as string;
  }

  protected readonly faArrowFromLeft = faArrowFromLeft;
}
