import { Component, inject } from '@angular/core';
import { ActiveAssignmentStoreService } from '@scheduler-frontend/data-access-assignment';
import { AssignmentHasSlotItemComponent } from './assignment-has-slot-item/assignment-has-slot-item.component';

@Component({
  selector: 'app-assignment-has-slot-table',
  standalone: true,
  templateUrl: './assignment-has-slot-table.component.html',
  styleUrls: ['./assignment-has-slot-table.component.scss'],
  imports: [AssignmentHasSlotItemComponent],
})
export class AssignmentHasSlotTableComponent {
  protected readonly activeAssignmentStoreService = inject(ActiveAssignmentStoreService);
}
