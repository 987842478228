import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  IonButton,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  ModalController,
} from '@ionic/angular/standalone';
import { AssignmentAssignmentHasSlotContract } from '@scheduler-frontend/assignment-contracts';
import {
  ActiveAssignmentStoreService,
  ArticleCodeEnumDisplayValues,
  AssignmentsPayoutStoreService,
  AssignmentsStoreService,
  PayoutArticleCodeEnum,
} from '@scheduler-frontend/data-access-assignment';
import { TtModalComponent, TtModalTitleComponent } from '@techniek-team/components/modal';
import { TtNumberInputControlComponent } from '@techniek-team/components/number-input';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'app-add-compensation-line-modal',
  templateUrl: './add-compensation-line-modal.component.html',
  styleUrls: ['./add-compensation-line-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TtModalComponent,
    ReactiveFormsModule,
    TtNumberInputControlComponent,
    DatePipe,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonButton,
    IonSpinner,
    TtModalTitleComponent,
  ],
})
export class AddCompensationLineModalComponent {
  private readonly modalController = inject(ModalController);

  protected readonly assignmentsStoreService = inject(AssignmentsStoreService);

  protected readonly activeAssignmentStoreService = inject(ActiveAssignmentStoreService);

  protected readonly assignmentsPayoutStoreService = inject(AssignmentsPayoutStoreService);

  protected readonly compensationLineForm = new FormGroup({
    article: new FormControl<PayoutArticleCodeEnum | null>(null, [Validators.required]),
    description: new FormControl<string | null>(null, [Validators.required]),
    assignmentHasSlot: new FormControl<AssignmentAssignmentHasSlotContract | null>(null, [
      Validators.required,
    ]),
    quantity: new FormControl<string | null>(null, [
      Validators.required,
      Validators.pattern('^\\s*(?=.*[1-9])\\d+(\\.\\d{1,2})?\\s*$'),
    ]),
    amount: new FormControl<string | null>(null, [
      Validators.required,
      Validators.pattern('^\\s*(?=.*[1-9])\\d+(\\.\\d{2})?\\s*$'),
    ]),
  });

  protected readonly article = toSignal(this.compensationLineForm.controls.article.valueChanges);

  protected readonly selectableAssignmentHasSlots = computed(() => {
    const article = this.article();
    const activeAssignmentCompensation =
      this.assignmentsPayoutStoreService.activeAssignmentCompensation();

    if (!activeAssignmentCompensation) {
      return [] as AssignmentAssignmentHasSlotContract[];
    }
    // Find the assignment has slot ids that are linked to assignment
    // compensations which are valid options based on the selected article
    // code.
    const validAssignmentHasSlotIds: string[] =
      activeAssignmentCompensation.assignmentCompensationLines
        .filter((compensationLine) => {
          if (article === PayoutArticleCodeEnum.REIMBURSEMENT) {
            return compensationLine.compensation?.allowExtraExpenses;
          }

          if (article === PayoutArticleCodeEnum.REMUNERATION) {
            return compensationLine.compensation?.allowExtraHours;
          }

          return article === PayoutArticleCodeEnum.SLOT_PREMIUM;
        })
        .map((compensationLine) => compensationLine.assignmentHasSlot);

    return this.activeAssignmentStoreService
      .assignment()
      ?.assignmentHasSlots.filter((assignmentHasSlot) => {
        return validAssignmentHasSlotIds.includes(assignmentHasSlot['@id'] as string);
      });
  });

  protected readonly ArticleCodeEnumDisplayValues = ArticleCodeEnumDisplayValues;

  protected onFormValueChange = this.compensationLineForm.controls.article.valueChanges
    .pipe(takeUntilDestroyed(), startWith(null))
    .subscribe((articleCode: PayoutArticleCodeEnum | null) => {
      const control = this.compensationLineForm.controls.assignmentHasSlot;
      control.reset(null);

      if (articleCode) {
        control.enable();
      } else {
        control.disable();
      }
    });

  protected handleSlotPremiumArticle = this.compensationLineForm.controls.article.valueChanges
    .pipe(takeUntilDestroyed(), startWith(null))
    .subscribe((articleCode: PayoutArticleCodeEnum | null) => {
      const quantityControl = this.compensationLineForm.controls.quantity;
      const amountControl = this.compensationLineForm.controls.amount;

      if (articleCode === PayoutArticleCodeEnum.SLOT_PREMIUM) {
        quantityControl.setValue('1');
        quantityControl.disable();

        amountControl.addValidators(Validators.max(100));
      } else {
        quantityControl.enable();

        amountControl.removeValidators(Validators.max(100));
      }

      amountControl.updateValueAndValidity();
    });

  public dismiss(): Promise<boolean> {
    return this.modalController.dismiss(null, 'cancel');
  }

  public addLine(): Promise<boolean> {
    if (!this.compensationLineForm.valid) {
      return Promise.resolve(false);
    }
    const activeAssignment = this.activeAssignmentStoreService.assignment();
    const formData = this.compensationLineForm.getRawValue();
    const date: string | null = (formData.assignmentHasSlot as AssignmentAssignmentHasSlotContract)
      .slot.timePeriod.start;
    this.assignmentsPayoutStoreService.addCompensationLine({
      articleCode: formData.article as PayoutArticleCodeEnum,
      description: formData.description as string,
      amount: (formData.amount as string).toString(),
      quantity: (formData.quantity as string).toString(),
      assignmentHasSlot: jsonLdSelectId(formData.assignmentHasSlot),
      subtotal: parseFloat(formData.quantity as string) * parseFloat(formData.amount as string),
      date: date ?? activeAssignment?.assignmentHasSlots[0].slot.timePeriod.start,
    });

    return this.modalController.dismiss(null, 'confirm');
  }
}
