import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonToggle, ToggleCustomEvent } from '@ionic/angular/standalone';
import { AssignmentsPayoutStoreService } from '@scheduler-frontend/data-access-assignment';

@Component({
  selector: 'app-edit-payout',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, IonToggle],
  templateUrl: './edit-payout.component.html',
  styleUrls: ['./edit-payout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditPayoutComponent {
  protected readonly assignmentsPayoutStoreService = inject(AssignmentsPayoutStoreService);

  protected updateAssignmentPayout(
    type: 'mustPerformCompensation' | 'automaticTravelCompensation',
    event: ToggleCustomEvent,
  ) {
    this.assignmentsPayoutStoreService.updateAssignment({
      [type]: event.detail.checked as boolean,
    });
  }
}
