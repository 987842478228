import { Component, input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { AssignmentStateDisplayValues } from '@scheduler-frontend/assignment-contracts';
import { AssignmentTransitionContract } from '@scheduler-frontend/data-access-assignment';

@Component({
  selector: 'app-transition-log-record',
  templateUrl: './transition-log-record.component.html',
  styleUrls: ['./transition-log-record.component.scss'],
  standalone: true,
  imports: [FontAwesomeModule],
})
export class TransitionLogRecordComponent {
  protected readonly AssignmentStateDisplayValues = AssignmentStateDisplayValues;

  protected readonly faExclamationCircle = faExclamationCircle;

  public logRecord = input.required<AssignmentTransitionContract>();
}
