/** @format **/
import { AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  computed,
  ElementRef,
  forwardRef,
  HostBinding,
  inject,
  Injector,
  input,
  Input,
  OnInit,
  Renderer2,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { IonButton, IonIcon, IonInput, IonLabel, IonNote } from '@ionic/angular/standalone';
import { MaskitoDirective } from '@maskito/angular';
import { MaskitoOptions, maskitoTransform } from '@maskito/core';
import { maskitoNumberOptionsGenerator, maskitoParseNumber } from '@maskito/kit';
import {
  fixFormControlMarkAs,
  getElementFont,
  getElementLetterSpacing,
  getTextDimensions,
} from '@techniek-team/common';
import { IonColor } from '@techniek-team/lyceo-style';
import { addIcons } from 'ionicons';
import { addOutline, removeOutline } from 'ionicons/icons';
import { injectResize } from 'ngxtension/resize';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

type OnChangeCallback = (output: number | null) => void;
type OnTouchCallback = () => void;
type OnValidCallback = () => void;

@Component({
  selector: 'tt-number-input',
  templateUrl: './tt-number-input-control.component.html',
  styleUrls: ['./tt-number-input-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TtNumberInputControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TtNumberInputControlComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    MaskitoDirective,
    FormsModule,
    ReactiveFormsModule,
    AsyncPipe,
    IonLabel,
    IonButton,
    IonIcon,
    IonInput,
    IonNote,
  ],
})
export class TtNumberInputControlComponent
  implements OnInit, ControlValueAccessor, AfterViewInit, Validator
{
  private elementRef: ElementRef = inject(ElementRef);

  private injector: Injector = inject(Injector);

  private renderer: Renderer2 = inject(Renderer2);

  /**
   * If `true`, the value will be cleared after focus upon edit. Defaults to `true` when `type` is `"password"`,
   * `false` for all other types.
   */
  public readonly clearOnEdit = input<boolean | undefined>(undefined);

  /**
   * The color to use from your application's color palette. Default options are: `"primary"`, `"secondary"`,
   * `"tertiary"`, `"success"`, `"warning"`, `"danger"`, `"light"`, `"medium"`, and `"dark"`. For more information on
   * colors, see [theming](/docs/theming/basics).
   */
  public readonly color = input<IonColor | undefined>(undefined);

  /**
   * Text that is placed under the input and displayed when an error is detected.
   */
  public readonly errorText = input<string | undefined>(undefined);

  /**
   * The fill for the item. If `"solid"` the item will have a background. If `"outline"` the item will be transparent
   * with a border. Only available in `md` mode.
   */
  public readonly fill = input<'outline' | 'solid' | undefined>(undefined);

  /**
   * Text that is placed under the input and displayed when no error is detected.
   */
  public readonly helperText = input<string | undefined>(undefined);

  /**
   * Input for the label tekst
   */
  public readonly label = input<string | undefined>(undefined);

  /**
   * Where to place the label relative to the input. `"start"`: The label will appear to the left of the input in LTR
   * and to the right in RTL. `"end"`: The label will appear to the right of the input in LTR and to the left in RTL.
   * `"floating"`: The label will appear smaller and above the input when the input is focused or it has a value.
   * Otherwise it will appear on top of the input. `"stacked"`: The label will appear smaller and above the input
   * regardless even when the input is blurred or has no value. `"fixed"`: The label has the same behavior as
   * `"start"` except it also has a fixed width. Long text will be truncated with ellipses ("...").
   */
  public readonly labelPlacement = input<'start' | 'end' | 'floating' | 'stacked' | 'fixed'>(
    'start',
  );

  /**
   * set the aria label which ionic requires
   */
  public readonly ariaLabel = input<string | undefined>(undefined);

  /**
   * Input for the minimum number they can be used as input.
   */
  public readonly min = input<number | undefined>(undefined);

  /**
   * Input for the maximum number they can be used as input.
   */
  public readonly max = input<number | undefined>(undefined);

  /**
   * Whether to allow decimals.
   */
  public readonly allowDecimals = input<boolean>(false);

  /**
   * A placeholder number
   */
  public readonly placeholder = input<number | undefined>(undefined);

  /**
   * Press interval (in number of seconds) to register the long click press.
   */
  public readonly longPressInterval = input<number>(0.1);

  protected readonly longPressIntervalInternal = signal(this.longPressInterval());

  /**
   * Number added or subtracted on button clicked.
   */
  public readonly stepInterval = input<number>(this.allowDecimals() ? 0.1 : 1);

  protected readonly stepIntervalInternal = signal(this.stepInterval());

  /**
   * Number added or subtracted on button clicked with a long press.
   */
  public readonly stepLongPressStepInterval = input<number>(0.1);

  protected readonly stepLongPressStepIntervalInternal = signal(this.stepLongPressStepInterval());

  /**
   * "block" for a full-width input or undefined for minimum needed
   */
  @HostBinding('class.block') @Input() public expand?: 'block';

  /**
   * FormControl that holds the current input.
   */
  public numberControl!: FormControl<number | null>;

  public maskItoConfig!: MaskitoOptions;

  protected maskControl!: FormControl<string | null>;

  protected readonly resize = toSignal(injectResize());

  //eslint-disable-next-line complexity,max-lines-per-function
  protected contentWidth = computed(() => {
    this.resize();

    let contentWidth: { min: string; max: string } = { min: '0', max: 'auto' };
    let element: HTMLElement = this.elementRef.nativeElement.querySelector('ion-input');

    if (!element) {
      return contentWidth;
    }

    const placeholderWidth = this.getTextWidth(this.placeholder()?.toString(), element);
    if (placeholderWidth > 0) {
      contentWidth.min = placeholderWidth.toString();
    }

    const labelWidth = this.getTextWidth(this.label(), element);
    let maskSpacing: number = 0;

    if (
      labelWidth > 0 &&
      (this.labelPlacement() === 'stacked' || this.labelPlacement() === 'floating')
    ) {
      contentWidth.min = labelWidth.toString();
    }

    if (this.max() !== undefined) {
      const maximum: number = Math.max(Math.abs(this.min() ?? 0), Math.abs(this.max() ?? 0));
      //eslint-disable-next-line max-len
      contentWidth.max = `${Math.ceil(Math.max(this.getTextWidth(maximum.toString(), element), labelWidth)).toString()}px`;
      maskSpacing = this.getTextWidth(' ', element) * Math.floor(maximum.toString().length / 4);
    }

    if (this.min() !== undefined || labelWidth > 0) {
      const minimum: number = Math.min(Math.abs(this.min() ?? 0), Math.abs(this.max() ?? 0));
      //eslint-disable-next-line max-len
      contentWidth.min = `${Math.ceil(Math.max(this.getTextWidth(minimum.toString(), element), labelWidth)).toString()}px`;
      maskSpacing = Math.max(
        maskSpacing,
        this.getTextWidth(' ', element) * Math.floor(minimum.toString().length / 4),
      );
    }

    //eslint-disable-next-line max-len
    contentWidth.min = `var(--tt-number-input-width, calc(${contentWidth.min} + var(--padding-start) + var(--padding-end) + 3px + ${maskSpacing}px))`;
    contentWidth.max = `var(--tt-number-input-width, calc(${contentWidth.max} + var(--padding-start) + var(--padding-end) + 3px + ${maskSpacing}px))`;
    return contentWidth;
  });

  @HostBinding('class.has-focus') protected hasFocus: boolean = false;

  @HostBinding('class.fill-outline') protected fillOutline: boolean = false;

  @HostBinding('class.fill-solid') protected fillSolid: boolean = false;

  /**
   * Subject which completes all hot observers on emit.
   */
  private onDestroy$: Subject<void> = new Subject<void>();

  @HostBinding('class.disabled') protected disabled: boolean = false;

  protected static maskValidator(actualControl: AbstractControl): ValidatorFn {
    return (_control: AbstractControl): ValidationErrors | null => {
      const validators: ValidatorFn | null = actualControl.validator;
      if (validators) {
        return validators(actualControl);
      }
      return null;
    };
  }

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.maskItoConfig = maskitoNumberOptionsGenerator({
      decimalSeparator: ',',
      decimalPseudoSeparators: [',', '.'],
      thousandSeparator: ' ',
      precision: this.allowDecimals() ? 2 : 0,
      min: this.min(),
      max: this.max(),
    });

    if (!this.allowDecimals()) {
      this.stepIntervalInternal.set(Math.max(1, this.stepIntervalInternal()));
      this.stepLongPressStepIntervalInternal.set(
        Math.max(1, this.stepLongPressStepIntervalInternal()),
      );
    }
    this.numberControl = this.createForm();
    this.maskControl = new FormControl<string | null>(null, [
      TtNumberInputControlComponent.maskValidator(this.numberControl),
    ]);
    this.createOnChangeSubscriber();
    this.createFormControlSyncSubscribers();
    this.fillOutline = this.fill() === 'outline';
    this.fillSolid = this.fill() === 'solid';
  }

  /**
   * @inheritDoc
   */
  public ngAfterViewInit(): void {
    fixFormControlMarkAs(this.injector, this.numberControl, this.elementRef);
  }

  /**
   * @inheritDoc
   */
  public registerOnChange(fn: OnChangeCallback): void {
    this.onChange = fn;
  }

  /**
   * @inheritDoc
   */
  public registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  /**
   * @inheritDoc
   */
  public registerOnTouched(fn: OnTouchCallback): void {
    this.onTouch = fn;
  }

  /**
   * @inheritDoc
   */
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.numberControl.disable({ emitEvent: false });
      this.maskControl.disable({ emitEvent: false });
      this.disabled = true;
    } else {
      this.numberControl.enable({ emitEvent: false });
      this.maskControl.enable({ emitEvent: false });
      this.disabled = false;
    }
  }

  /**
   * @inheritDoc
   */
  public writeValue(value: number): void {
    if (!value) {
      // set default value;
      this.numberControl.reset();
      return;
    }

    const rounded: number = Math.round((value + Number.EPSILON) * 10) / 10;
    this.numberControl.setValue(rounded, { emitEvent: false, onlySelf: true });
    this.maskControl.setValue(maskitoTransform(rounded.toString() ?? '', this.maskItoConfig), {
      emitEvent: false,
    });
  }

  /**
   * @inheritDoc
   */
  public validate(control: AbstractControl): ValidationErrors | null {
    if (this.numberControl.invalid) {
      return this.numberControl.errors;
    }
    if (control.validator !== null) {
      return control.validator(this.numberControl);
    }

    return null;
  }

  /**
   * Cancels any currently running long press event.
   */
  public stopPressInterval(): void {
    clearInterval(this.longPressInterval());
    if (!this.allowDecimals()) {
      this.numberControl.setValue(
        this.numberControl.value ? Math.round(this.numberControl.value) : null,
      );
    }
    const ionItem: HTMLElement | null = this.elementRef.nativeElement.querySelector('ion-item');
    if (ionItem) {
      this.renderer.removeClass(ionItem, 'item-has-focus');
    }
  }

  /**
   * Method triggered when user clicks on the add button.
   * It adds stepInterval or 10 times the stepInterval depending on if the alt key is pressed.
   */
  public add(event: MouseEvent): void {
    this.stopPressInterval();
    this.internalAdd(event.altKey ? this.stepIntervalInternal() * 10 : this.stepInterval());
  }

  /**
   * Method triggered when the user long presses the add button.
   * It adds this.stepInterval every 10th of a second.
   */
  public longPressAdd(): void {
    this.longPressIntervalInternal.set(
      window.setInterval(() => this.internalAdd(this.stepLongPressStepIntervalInternal()), 100),
    );
    const ionItem: HTMLElement | null = this.elementRef.nativeElement.querySelector('ion-item');
    if (ionItem) {
      this.renderer.addClass(ionItem, 'item-has-focus');
    }
  }

  /**
   * Method triggered when user clicks on the subtract button.
   * It subtracts  stepInterval or 10 times the stepInterval depending on if the alt key is pressed.
   */
  public sub(event: MouseEvent): void {
    this.stopPressInterval();
    this.internalSub(event.altKey ? this.stepIntervalInternal() * 10 : this.stepIntervalInternal());
  }

  /**
   * Method triggered when the user long presses the subtract button.
   * It subtracts this.stepInterval every 10th of a second.
   */
  public longPressSub(): void {
    this.longPressIntervalInternal.set(
      window.setInterval(() => this.internalSub(this.stepLongPressStepIntervalInternal()), 100),
    );
  }

  protected maskPredicate(el: Element): Promise<HTMLInputElement> {
    return (el as HTMLIonInputElement).getInputElement();
  }

  /**
   * Round the input and check if value is within range of min max on blur.
   */
  protected checkAndRoundInput(): void {
    this.stopPressInterval();
    this.onTouch();
    let value: number =
      Math.round(((this.numberControl.value as number) + Number.EPSILON) * 10) / 10;

    const min: number | undefined = this.min();
    if (typeof min === 'number' && value <= min) {
      value = min;
    }

    const max: number | undefined = this.max();
    if (typeof max === 'number' && value >= max) {
      value = max;
    }

    this.numberControl.setValue(value, { emitEvent: false, onlySelf: true });
  }

  /**
   * the onChange callback
   */
  private onChange: OnChangeCallback = (_output: number | null) => {
    /* callback */
  };

  /**
   * the onTouch callback
   */
  private onTouch: OnTouchCallback = () => {
    /* callback */
  };

  /**
   * the onValidator callback.
   */
  private onValidatorChange: OnValidCallback = () => {
    /* callback */
  };

  private getTextWidth(text: string | undefined, element: HTMLElement): number {
    if (!text) {
      return 0;
    }
    return Math.ceil(
      getTextDimensions(text, getElementFont(element), getElementLetterSpacing(element)).width,
    );
  }

  private createFormControlSyncSubscribers(): void {
    this.numberControl.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      this.maskControl.setValue(maskitoTransform(value?.toString() ?? '', this.maskItoConfig), {
        emitEvent: false,
      });
    });
    this.maskControl.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      this.onTouch();
      this.numberControl.setValue(value ? maskitoParseNumber(value?.toString() ?? '', ',') : null);
    });
  }

  /**
   * Add 1 or this.stepInterval to the number depend on if the alt key was pressed.
   */
  private internalAdd(toAdd: number): void {
    let current: number = this.numberControl.value ?? 0;
    const max: number | undefined = this.max();
    if (typeof max === 'number' && current >= max) {
      return;
    }

    let newCurrent: number = current + toAdd;
    if (this.allowDecimals()) {
      newCurrent = Math.round((newCurrent + Number.EPSILON) * 10) / 10;
    }
    this.onTouch();

    const min: number | undefined = this.min();
    if (typeof min === 'number' && this.numberControl.value === null) {
      newCurrent = Math.max(newCurrent, min);
    }

    this.numberControl.setValue(newCurrent);
  }

  /**
   * Subtracts 1 or this.stepInterval to the number depend on if the alt key was pressed.
   */
  private internalSub(toSub: number): void {
    let current: number = this.numberControl.value ?? 0;
    const min: number | undefined = this.min();

    if (typeof min === 'number' && current <= min) {
      if (this.numberControl.value === null && current === 0) {
        this.numberControl.setValue(0);
      }
      return;
    }

    let newCurrent: number = current - toSub;
    if (this.allowDecimals()) {
      newCurrent = Math.round((newCurrent + Number.EPSILON) * 10) / 10;
    }

    this.onTouch();
    this.numberControl.setValue(newCurrent);
  }

  /**
   * Create the input form.
   */
  private createForm(): FormControl<number | null> {
    const validators: ValidatorFn[] = [];
    const min: number | undefined = this.min();
    if (typeof min === 'number') {
      validators.push(Validators.min(min));
    }

    const max: number | undefined = this.max();
    if (typeof max === 'number') {
      validators.push(Validators.max(max));
    }

    if (!this.allowDecimals()) {
      validators.push(Validators.pattern(/^-?(-1|\d+)?$/));
    }

    return new FormControl<number | null>(null, validators);
  }

  /**
   * Create a subscriber which emits the changed values to the onChange.
   */
  private createOnChangeSubscriber(): void {
    this.numberControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((change) => {
      this.onValidatorChange();
      this.onChange(change);
    });
  }

  constructor() {
    addIcons({ removeOutline: removeOutline, addOutline: addOutline });
  }
}
