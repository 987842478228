import { ChangeDetectionStrategy, Component, computed, effect, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonItem, IonList, IonListHeader, IonSpinner, IonToggle } from '@ionic/angular/standalone';
import {
  ActiveAssignmentsPermissionsStoreService,
  ActiveAssignmentStoreService,
} from '@scheduler-frontend/data-access-assignment';
import { TtNumberInputControlComponent } from '@techniek-team/components/number-input';
import { debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: 'app-self-assign-form',
  templateUrl: './self-assign-form.component.html',
  styleUrls: ['./self-assign-form.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    IonItem,
    IonToggle,
    IonList,
    IonListHeader,
    IonSpinner,
    TtNumberInputControlComponent,
  ],
})
export class SelfAssignFormComponent {
  protected readonly activeAssignmentStoreService = inject(ActiveAssignmentStoreService);

  protected readonly activeAssignmentsPermissionsStoreService = inject(
    ActiveAssignmentsPermissionsStoreService,
  );

  private disableMinimalGrade = effect(() => {
    const businessService = this.activeAssignmentStoreService.assignmentBusinessService();
    if (businessService?.hasAssignmentReviews) {
      this.selfAssignForm().controls.minimalGradeSelfAssign.disable();
    }
  });

  protected readonly selfAssignForm = computed(() => {
    const assignment = this.activeAssignmentStoreService.assignment();
    return new FormGroup({
      selfAssignable: new FormControl<boolean>(assignment?.selfAssignable ?? false, {
        nonNullable: true,
      }),
      allowSelfAssignWhenNew: new FormControl<boolean>(
        { value: assignment?.allowSelfAssignWhenNew ?? false, disabled: true },
        { nonNullable: true },
      ),
      minimalGradeSelfAssign: new FormControl<number | null>(
        { value: assignment?.minimalGradeSelfAssign ?? 7.5, disabled: true },
        [Validators.min(0), Validators.max(10)],
      ),
      maxTravelDistanceSelfAssign: new FormControl<number | null>(
        {
          value: assignment?.maxTravelDistanceSelfAssign ?? null,
          disabled: true,
        },
        [Validators.min(0), Validators.max(350)],
      ),
    });
  });

  protected readonly canEditSelfAssignable = effect(() => {
    const granted = this.activeAssignmentsPermissionsStoreService.canEditSelfAssignable();
    if (granted) {
      this.selfAssignForm().enable({ emitEvent: false });
    } else {
      this.selfAssignForm().disable({ emitEvent: false });
    }
  });

  private readonly disableFormOnSelfAssignableChange = this.selfAssignForm()
    .controls.selfAssignable.valueChanges.pipe(takeUntilDestroyed())
    .subscribe((selfAssignable) => {
      if (selfAssignable) {
        this.selfAssignForm().controls.allowSelfAssignWhenNew.enable({ emitEvent: false });
        this.selfAssignForm().controls.minimalGradeSelfAssign.enable({ emitEvent: false });
        this.selfAssignForm().controls.maxTravelDistanceSelfAssign.enable({ emitEvent: false });
      } else {
        this.selfAssignForm().controls.allowSelfAssignWhenNew.disable({ emitEvent: false });
        this.selfAssignForm().controls.minimalGradeSelfAssign.disable({ emitEvent: false });
        this.selfAssignForm().controls.maxTravelDistanceSelfAssign.disable({ emitEvent: false });
      }
    });

  private readonly saveOnChange = this.selfAssignForm()
    .valueChanges.pipe(
      filter(() => {
        return this.selfAssignForm().valid && this.selfAssignForm().dirty;
      }),
      debounceTime(2000),
    )
    .subscribe((formValue) => {
      this.activeAssignmentStoreService.setActiveAssignmentSelfAssignmentSettings(
        formValue.selfAssignable as boolean,
        formValue.allowSelfAssignWhenNew as boolean,
        formValue.minimalGradeSelfAssign,
        formValue.maxTravelDistanceSelfAssign,
      );
    });
}
