import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatCell,
  MatColumnDef,
  MatFooterCell,
  MatFooterCellDef,
  MatFooterRow,
  MatFooterRowDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
} from '@angular/material/table';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import {
  IonButton,
  IonIcon,
  IonProgressBar,
  IonSpinner,
  ModalController,
} from '@ionic/angular/standalone';
import {
  ActiveAssignmentsPermissionsStoreService,
  ActiveAssignmentStoreService,
  ArticleCodeEnumDisplayValues,
  AssignmentCompensationLineContract,
  AssignmentsPayoutStoreService,
  AssignmentsStoreService,
  PayoutArticleCodeEnum,
} from '@scheduler-frontend/data-access-assignment';
import { BusinessServicesStoreService } from '@scheduler-frontend/data-access-business-services';
import { RolesStoreService } from '@scheduler-frontend/data-access-roles';
import { TypeSafeMatCellDef } from '@scheduler-frontend/mat-cell-def';
import { JsonLdSelectIdPipe } from '@techniek-team/tt-ngrx';
import { addIcons } from 'ionicons';
import { trashOutline } from 'ionicons/icons';
import { AddCompensationLineModalComponent } from './add-compensation-line-modal/add-compensation-line-modal.component';
import { EditPayoutComponent } from './edit-payout/edit-payout.component';
import { PayoutInformationComponent } from './payout-information/payout-information.component';

@Component({
  selector: 'app-payout-details',
  templateUrl: './payout.component.html',
  styleUrls: ['./payout.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PayoutInformationComponent,
    ReactiveFormsModule,
    DecimalPipe,
    DatePipe,
    CurrencyPipe,
    EditPayoutComponent,
    IonProgressBar,
    IonButton,
    IonIcon,
    IonSpinner,
    MatColumnDef,
    MatHeaderCellDef,
    TypeSafeMatCellDef,
    MatCell,
    MatTable,
    MatHeaderCell,
    MatFooterCell,
    MatFooterCellDef,
    JsonLdSelectIdPipe,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRowDef,
    MatRow,
    MatFooterRow,
    MatFooterRowDef,
    FaIconComponent,
  ],
})
export class PayoutComponent {
  protected readonly assignmentsStoreService = inject(AssignmentsStoreService);

  protected readonly rolesStoreService = inject(RolesStoreService);

  protected readonly businessServicesStoreService = inject(BusinessServicesStoreService);

  protected readonly activeAssignmentStoreService = inject(ActiveAssignmentStoreService);

  protected readonly assignmentsPayoutStoreService = inject(AssignmentsPayoutStoreService);

  protected readonly activeAssignmentsPermissionsStoreService = inject(
    ActiveAssignmentsPermissionsStoreService,
  );

  protected readonly modalController = inject(ModalController);

  protected readonly PayoutArticleCodeEnum = PayoutArticleCodeEnum;

  protected readonly ArticleCodeEnumDisplayValues = ArticleCodeEnumDisplayValues;

  protected readonly faEyeSlash = faEyeSlash;

  constructor() {
    addIcons({ trashOutline: trashOutline });
  }

  protected async addLine(): Promise<void> {
    const confirmModal: HTMLIonModalElement = await this.modalController.create({
      component: AddCompensationLineModalComponent,
      showBackdrop: true,
      cssClass: ['stack-modal'],
    });
    return confirmModal.present();
  }

  protected deleteLine(compensationLine: string): void {
    this.assignmentsPayoutStoreService.removeCompensationLine(compensationLine);
  }

  protected assignmentCompensationLinesTrackBy(
    _index: number,
    item: AssignmentCompensationLineContract,
  ): string {
    return item['@id'];
  }
}
